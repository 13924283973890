<template>
  <div class="faq">
    <PageTitle title="Frågor och Svar"/>

    <div class="wrapper">
      <div class="container">
        <div class="faq-content">
          <div class="faq-content-question"><strong>Varför görs detta?</strong></div>
          <div class="faq-content-answer">
            <p>Sverige har en bredbandsinfrastruktur i världsklass med
              väl utbyggda fiber- och mobilnät. Kopparnätet är en gammal teknik som inte längre
              lever
              upp till dagens krav och som idag används av allt färre.</p>
          </div>

          <div class="faq-content-question"><strong>Vad erbjuds kunderna istället?</strong></div>
          <div class="faq-content-answer">
            <p>Det är den samlade marknaden som erbjuder alternativ
              till
              kopparnätet. Kunder erbjuds i första hand nya lösningar via fiber eller
              mobilnätet.</p>

            <p>Alla som berörs kommer att kunna erbjudas en ny tjänst från en operatör på marknaden
              eller undantagsvis med stöd av <a
                target="_blank"
                href="https://pts.se/grundlaggandeinternet">Post-
                och Telestyrelsen</a>.</p>
          </div>

          <div class="faq-content-question"><strong>Vad händer om mobiltäckningen eller kapaciteten
            är
            dålig där jag bor eller har min verksamhet?</strong></div>
          <div class="faq-content-answer">
            <p>De flesta som berörs av kopparstängningen kan idag välja mellan att ansluta sig
              till
              ett fibernät eller tjänster via mobilnätet.</p>

            <p>De tjänster som flera operatörer erbjuder för bredband via mobilnätet har en
              utrustning med bättre mottagning jämfört med en mobiltelefon. Detta gör att det kan
              fungera även i områden där man upplever att det är dålig täckning med en
              mobiltelefon.
              Det finns också lösningar där mottagarenheten sätts på utsidan av huset, vilket ger
              ännu bättre mottagning.</p>

            <p>I de få fall där alternativ helt saknas träder Post- och telestyrelsen in och
              säkerställer att den lagliga rätten till telefoni och bredband uppfylls. <a
                target="_blank"
                href="https://pts.se/grundlaggandeinternet">Post-
                och
                Telestyrelsen</a>.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PageTitle from '@/components/PageTitle'

export default {
  components: { PageTitle }
}
</script>

<style lang="scss">
.faq {
  &-content {
    margin: 80px 0;

    &-answer {
      margin-bottom: 30px;
    }
  }
}
</style>
