<template>
  <div class="page-title">
    <div class="page-title-container">
      <h1>{{ title }}</h1>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageTitle',
  props: {
    title: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss">
  @import "~@/assets/style.scss";

  .page-title {
    text-align: left;
    background-color: $darkBlue;
    display: flex;

    @media(min-width: $breakpointDesktop) {
      justify-content: center;
    }

    &-container {
      padding: 0 15px;

      @media(min-width: $breakpointDesktop) {
        width: $containerSizeDesktop;
        padding: 3px 0;
      }
    }
  }
</style>
